.afb {
  &-header-menu {
    button.afb,
    .btn-login {
      background-color: transparent;
      border: 1px solid var(--mainTheme);
      color: var(--mainTheme);
      &:hover {
        background-image: linear-gradient(to right, var(--subTheme), var(--mainTheme));
      }
    }
  }
  &-main_nav {
    .megamenu {
      background: var(--mainTheme);
      .nav {
        &-item {
          &-content {
            background-color: var(--bg-mainTheme) !important;
            p {
              color: var(--text-mainTheme);
            }
          }
          &:hover {
            background-color: darken($mainTheme, 10%);
          }
        }
        &-link {
          &:hover {
            background-color: unset;
          }
        }
      }
    }
  }
}
.header-marquee {
  &.afb-black {
    background-color: initial !important;
  }
  .alert-danger {
    background-color: initial !important;
    .text-white {
      color: var(--text-mainTheme) !important;
    }
  }
}
.inside {
  background: #2c2c2c;
  // color: var(--text-mainTheme);
  h1 {
    color: var(--text-mainTheme);
  }
  .afb-medias {
    a {
      background-image: linear-gradient(to right, var(--mainTheme), var(--subTheme));
      &:hover {
        background-image: linear-gradient(to right, var(--subTheme), var(--mainTheme));
      }
    }
  }
  .search-btn {
    background-image: linear-gradient(to right, var(--mainTheme), var(--subTheme));
    &:hover {
      background-image: linear-gradient(to right, var(--subTheme), var(--mainTheme));
    }
  }
  // .input-group {
  //   input {
  //     border-color: var(--mainTheme) !important;
  //   }
  //   .input-group-append {
  //     button {
  //       background-color: var(--mainTheme);
  //     }
  //   }
  // }
}
.text-wrap {
  background-color: darken($bg-maintheme, 2%);
  // background-color: var(--bg-mainTheme);
  color: var(--text-mainTheme);
}
.text-truncate {
  color: var(--text-mainTheme);
}
.inside-tab {
  background-color: darken($bg-maintheme, 10%);
}
.wallet-top {
  &-left {
    background: darken($bg-maintheme, 30%);
    span {
      color: var(--text-mainTheme);
    }
  }
  &-right {
    background: darken($bg-maintheme, 20%);
    span {
      color: var(--text-mainTheme);
    }
  }
}
.sharebox-container {
  background-color: darken($bg-maintheme, 20%);
  .share-title {
    color: var(--text-mainTheme) !important;
  }
}
.btn-sharebox-gray {
  background-color: darken($bg-maintheme, 20%);
  color: var(--text-mainTheme) !important;
  // img {
  //   filter: brightness(0);
  // }
}
.btn-tnc {
  background-color: darken($bg-maintheme, 20%);
  color: var(--text-mainTheme);
  &:hover {
    background-color: darken($bg-maintheme, 30%);
    color: var(--text-mainTheme);
  }
}

.user-profile {
  .afb-blue-bar {
    background-color: var(--mainTheme);
    .toggleLeft-profile,
    .toggleRight-profile {
      background: transparent;
    }
  }
  .cd-tabs__list {
    li {
      span,
      .icon {
        color: var(--text-mainTheme);
      }
    }
    .cd-tabs__item {
      &--selected {
        span,
        .icon {
          color: var(--darkTheme);
        }
      }
    }
  }
  .game-part h3 {
    background: darken($lightTheme, 75%);
    color: var(--text-mainTheme);
  }
  #profile-Bet-History,
  #profile-promotion-sec {
    span {
      color: #999;
    }
    button {
      color: var(--text-mainTheme);
    }
  }
}

.blog-title {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: var(--lightTheme);
  }
}

#dls-container {
  .luckspin_logo {
    background: var(--subTheme);
    box-shadow: none;
  }
  .modal-content.oned {
    background: linear-gradient(0deg, darken($subTheme, 20%) 0%, var(--subTheme) 35%, lighten($subTheme, 10%) 100%);
    border-color: darken($subTheme, 25%);
  }
  .bonus-dls {
    &-bg {
      background: darken($subTheme, 20%);
    }
  }
}
.dls-overlay {
  .dls {
    &-pop {
      &-bg {
        background: linear-gradient(lighten($mainTheme, 10%), var(--mainTheme), darken($mainTheme, 10%));
      }
      &-button {
        background: linear-gradient(lighten($mainTheme, 10%), var(--mainTheme));
      }
    }
    &-content {
      h5 {
        color: var(--mainTheme);
      }
    }
  }
}
.s006 form {
  .suggestion-wrap {
    span:hover {
      background-image: linear-gradient(to right, var(--subTheme), var(--mainTheme));
    }
  }
}