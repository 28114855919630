@charset "UTF-8";
/* bootstrap */
/* required */
/* bootstrap */
:root {
  --lightTheme: #fff;
  --darkTheme: #141314;
  --mainTheme: #fec33f;
  --subTheme: #feb30c;
  --bg-mainTheme: var(--darkTheme);
  --text-mainTheme: var(--lightTheme);
}

body {
  background-color: var(--bg-mainTheme);
}

input,
textarea {
  border-color: #c0c0c0 !important;
}
input:focus,
textarea:focus {
  border-color: #ffe3a4 !important;
}

button.afb01,
.button.afb01 {
  background-image: linear-gradient(to right, var(--mainTheme), var(--subTheme));
}
button.afb01:hover,
.button.afb01:hover {
  background-image: linear-gradient(to right, var(--subTheme), var(--mainTheme));
}
button.afb01.xs,
.button.afb01.xs {
  background-image: linear-gradient(to right, var(--mainTheme), var(--subTheme));
}
button.afb01.xs:hover,
.button.afb01.xs:hover {
  background-image: linear-gradient(to right, var(--subTheme), var(--mainTheme));
}
button.afb-m-menu,
.button.afb-m-menu {
  border-color: var(--mainTheme);
}
button.afb-m-menu:hover,
.button.afb-m-menu:hover {
  background-image: linear-gradient(to right, var(--subTheme), var(--mainTheme));
}
button-afb.one,
.button-afb.one {
  background-image: linear-gradient(to right, var(--mainTheme), var(--subTheme));
}
button-afb.one:hover,
.button-afb.one:hover {
  background-image: linear-gradient(to right, var(--subTheme), var(--mainTheme));
}

.btn-icontop-lg {
  background-color: var(--mainTheme);
}
.btn-icontop-lg:hover {
  background-color: #efa601 !important;
}
.btn-color {
  background-image: linear-gradient(to right, var(--mainTheme), var(--subTheme));
}
.btn-color:hover {
  background-image: linear-gradient(to right, var(--subTheme), var(--mainTheme));
}
.btn-white {
  color: var(--mainTheme);
  border-color: var(--mainTheme);
}
.btn-sharebox {
  background-image: linear-gradient(to left, var(--mainTheme), var(--subTheme));
}
.btn-sharebox:hover {
  background-image: linear-gradient(to left, var(--subTheme), var(--mainTheme));
}

.filter-button.active, .filter-button:hover {
  background-image: linear-gradient(to right, var(--subTheme), var(--mainTheme));
}

.dropdown-single:hover .dropbtn-single {
  background: none;
}

.pagination > li > a.one {
  color: var(--text-mainTheme);
}

.pagination > li > a {
  color: #000;
}

.paginationjs-page.J-paginationjs-page.active a {
  background-image: linear-gradient(to right, var(--mainTheme), var(--subTheme));
  color: #000 !important;
}
.paginationjs-page.J-paginationjs-page.active a:hover {
  background-image: linear-gradient(to right, var(--subTheme), var(--mainTheme));
}

.modal2 .modal-content .popup-footer button {
  background: var(--mainTheme);
  border-color: var(--mainTheme);
}

.choose-file::-webkit-file-upload-button {
  background: var(--mainTheme);
}

.unreadMSG,
.notifUnread {
  background-color: white;
}

.notif-hd-title {
  background-color: #feb30c;
}

#dls-mobile {
  color: #fff;
}

.afb-header-menu button.afb,
.afb-header-menu .btn-login {
  background-color: transparent;
  border: 1px solid var(--mainTheme);
  color: var(--mainTheme);
}
.afb-header-menu button.afb:hover,
.afb-header-menu .btn-login:hover {
  background-image: linear-gradient(to right, var(--subTheme), var(--mainTheme));
}
.afb-main_nav .megamenu {
  background: var(--mainTheme);
}
.afb-main_nav .megamenu .nav-item-content {
  background-color: var(--bg-mainTheme) !important;
}
.afb-main_nav .megamenu .nav-item-content p {
  color: var(--text-mainTheme);
}
.afb-main_nav .megamenu .nav-item:hover {
  background-color: #feb30c;
}
.afb-main_nav .megamenu .nav-link:hover {
  background-color: unset;
}

.header-marquee.afb-black {
  background-color: initial !important;
}
.header-marquee .alert-danger {
  background-color: initial !important;
}
.header-marquee .alert-danger .text-white {
  color: var(--text-mainTheme) !important;
}

.inside {
  background: #2c2c2c;
}
.inside h1 {
  color: var(--text-mainTheme);
}
.inside .afb-medias a {
  background-image: linear-gradient(to right, var(--mainTheme), var(--subTheme));
}
.inside .afb-medias a:hover {
  background-image: linear-gradient(to right, var(--subTheme), var(--mainTheme));
}
.inside .search-btn {
  background-image: linear-gradient(to right, var(--mainTheme), var(--subTheme));
}
.inside .search-btn:hover {
  background-image: linear-gradient(to right, var(--subTheme), var(--mainTheme));
}

.text-wrap {
  background-color: #0f0e0f;
  color: var(--text-mainTheme);
}

.text-truncate {
  color: var(--text-mainTheme);
}

.inside-tab {
  background-color: black;
}

.wallet-top-left {
  background: black;
}
.wallet-top-left span {
  color: var(--text-mainTheme);
}
.wallet-top-right {
  background: black;
}
.wallet-top-right span {
  color: var(--text-mainTheme);
}

.sharebox-container {
  background-color: black;
}
.sharebox-container .share-title {
  color: var(--text-mainTheme) !important;
}

.btn-sharebox-gray {
  background-color: black;
  color: var(--text-mainTheme) !important;
}

.btn-tnc {
  background-color: black;
  color: var(--text-mainTheme);
}
.btn-tnc:hover {
  background-color: black;
  color: var(--text-mainTheme);
}

.user-profile .afb-blue-bar {
  background-color: var(--mainTheme);
}
.user-profile .afb-blue-bar .toggleLeft-profile,
.user-profile .afb-blue-bar .toggleRight-profile {
  background: transparent;
}
.user-profile .cd-tabs__list li span,
.user-profile .cd-tabs__list li .icon {
  color: var(--text-mainTheme);
}
.user-profile .cd-tabs__list .cd-tabs__item--selected span,
.user-profile .cd-tabs__list .cd-tabs__item--selected .icon {
  color: var(--darkTheme);
}
.user-profile .game-part h3 {
  background: #404040;
  color: var(--text-mainTheme);
}
.user-profile #profile-Bet-History span,
.user-profile #profile-promotion-sec span {
  color: #999;
}
.user-profile #profile-Bet-History button,
.user-profile #profile-promotion-sec button {
  color: var(--text-mainTheme);
}

.blog-title h1,
.blog-title h2,
.blog-title h3,
.blog-title h4,
.blog-title h5,
.blog-title h6 {
  color: var(--lightTheme);
}

#dls-container .luckspin_logo {
  background: var(--subTheme);
  box-shadow: none;
}
#dls-container .modal-content.oned {
  background: linear-gradient(0deg, #a37101 0%, var(--subTheme) 35%, #fec33f 100%);
  border-color: #8a5f01;
}
#dls-container .bonus-dls-bg {
  background: #a37101;
}

.dls-overlay .dls-pop-bg {
  background: linear-gradient(#fed372, var(--mainTheme), #feb30c);
}
.dls-overlay .dls-pop-button {
  background: linear-gradient(#fed372, var(--mainTheme));
}
.dls-overlay .dls-content h5 {
  color: var(--mainTheme);
}

.s006 form .suggestion-wrap span:hover {
  background-image: linear-gradient(to right, var(--subTheme), var(--mainTheme));
}

.afb-logo {
  width: 20%;
  text-align: left;
}

.afb-logo img {
  width: 100%;
}

.header-memu-m {
  padding-top: 7px;
  width: 74%;
  text-align: right;
}

button.afb-m-menu, .button.afb-m-menu {
  width: 30px;
  height: 30px;
}

button.afb-m-menu svg {
  left: -5px;
  position: relative;
  top: 0px;
}

.btn-lite {
  width: 85px;
  height: 30px;
  white-space: nowrap;
  padding: 5px 3px !important;
  display: none;
}

.btn-login {
  min-width: 60px;
  height: 30px;
  padding: 5px 3px !important;
}

.button-register {
  min-width: 70px;
  height: 30px;
  padding: 5px 3px !important;
}

.btn-login, .button-register, .btn-lite, .afb-header-menu .login {
  max-width: none;
  border-radius: 10px;
}

.navbar {
  border-bottom: 3px solid var(--mainTheme);
}

.afb-header-menu .row {
  justify-content: right;
  align-items: center;
}

.afb-main_nav {
  display: none;
}

.notificationMarquee p {
  line-height: 13px;
}

ul, li {
  margin: 0;
  padding: 0;
  list-style: none;
}

.index_tabs {
  clear: left;
  width: 100%;
  margin: 10px 0;
  height: 58vh;
}

ul.tabs {
  width: 30%;
  height: 58vh;
  float: left;
  overflow-y: auto;
}

ul.tabs li {
  width: 100%;
  height: 9.75vh;
  overflow: hidden;
  position: relative;
}

ul.tabs li a {
  display: block;
  height: 9.25vh;
  padding: 5px 10px;
  color: var(--mainTheme);
  border: 2px solid var(--mainTheme);
  border-right: 0;
  background: #131413;
  text-decoration: none;
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
  text-align: center;
  position: relative;
}

ul.tabs li a img {
  width: 4.5vh;
  filter: invert(55%) sepia(300%) contrast(200%);
  padding-bottom: 0.5vh;
}

ul.tabs li a span {
  font-weight: 500;
  white-space: nowrap;
  display: block;
  width: 100%;
  text-align: center;
}

ul.tabs li.active a {
  background: var(--mainTheme);
  color: #131413;
  border-right: 2px solid var(--mainTheme);
}

ul.tabs li.active a img {
  filter: none !important;
}

div.tab_container {
  float: right;
  width: 70%;
  height: 100%;
  border: 2px solid var(--mainTheme);
  background: var(--mainTheme);
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
}

div.tab_container .tab_content {
  padding: 2px;
  height: 100%;
  background-color: #131413;
  border-radius: 14px;
  overflow-y: auto;
  margin: 1px;
  border: 4px solid #131413;
}

div.tab_container .tab_content .gameButton {
  width: 49%;
  float: left;
  margin-right: 2%;
  margin-bottom: 5px;
}

div.tab_container .tab_content .gameButton:nth-child(2n) {
  margin-right: 0%;
}

div.tab_container .tab_content .gameButton a {
  border: 1px solid var(--mainTheme);
  display: block;
  border-radius: 12px;
  overflow: hidden;
}

div.tab_container .tab_content .gameButton a .gameImg {
  border-radius: 13px;
  width: 100%;
  height: 80px;
  overflow: hidden;
}

div.tab_container .tab_content .gameButton a .gameLogo {
  width: 60%;
  margin-left: 20%;
}

div.tab_container .tab_content::-webkit-scrollbar-track {
  border-left: 2px solid #131413;
  border-right: 1px solid #131413;
}

div.tab_container .tab_content::-webkit-scrollbar {
  width: 3px;
}

div.tab_container .tab_content::-webkit-scrollbar-thumb {
  border-left: 2px solid var(--mainTheme);
  border-right: 1px solid #131413;
  padding: 0;
}

div.tab_container .tab_content h2 {
  margin: 0 0 20px;
}

#mainContent {
  padding: 0 !important;
}

.content-section {
  margin-top: -12px;
}

.widget .card-header a:after {
  color: var(--mainTheme);
}

.afb-header-menu {
  width: 100%;
}

.logout-btn {
  display: block;
  float: right;
  margin-left: 5px;
  margin-right: 5px;
  width: 20px;
  height: 20px;
}

.menu-btn {
  display: block;
  float: right;
  margin-left: 5px;
  width: 20px;
  height: 20px;
}

.info-icon {
  top: 0px;
}

img.choose-icon {
  margin-right: 0px;
}

button.dropbtn-single-box {
  padding: 5px 5px;
}

#mainContent {
  min-height: auto;
}

.main-stracture {
  margin-top: -24px;
}

.nav-bottom .nav-bottom-home .megamenu-icon {
  width: calc(var(--size) * 0.38);
  height: calc(var(--size) * 0.38);
}

.dropdown-single-content a {
  width: 100%;
  text-align: left;
}

#gotoTop {
  display: none !important;
}

.content-section {
  min-height: none !important;
}

@media (max-width: 319px) and (min-width: 280px) {
  .index_tabs {
    height: 60vh;
  }

  ul.tabs li {
    height: 10.2vh;
  }

  ul.tabs li a {
    height: 9vh;
    padding: 5px 5px 5px 0px;
  }

  ul.tabs li a span {
    transform: scale(0.5);
  }

  ul.tabs {
    height: 60vh;
  }

  div.tab_container .tab_content .gameButton {
    height: 20vh;
    width: 100%;
  }

  div.tab_container .tab_content .gameButton a {
    height: 20vh;
  }

  div.tab_container .tab_content .gameButton a .gameImg {
    height: 12vh;
  }

  div.tab_container .tab_content .gameButton a .gameLogo {
    height: 8vh;
  }
}
@media (max-width: 375px) and (min-width: 320px) {
  .index_tabs {
    height: 60vh;
  }

  ul.tabs li {
    height: 10.2vh;
  }

  ul.tabs li a {
    height: 9vh;
  }

  ul.tabs {
    height: 60vh;
  }

  div.tab_container .tab_content .gameButton {
    height: 18vh;
  }

  div.tab_container .tab_content .gameButton a {
    height: 18vh;
  }

  div.tab_container .tab_content .gameButton a .gameImg {
    height: 12vh;
  }

  div.tab_container .tab_content .gameButton a .gameLogo {
    height: 6vh;
  }
}
@media (max-width: 414px) and (min-width: 391px) {
  .index_tabs {
    height: 70vh;
  }

  ul.tabs li {
    height: 11.8vh;
  }

  ul.tabs li a {
    height: 11vh;
  }

  ul.tabs {
    height: 70vh;
  }

  div.tab_container .tab_content .gameButton {
    height: 14vh;
  }

  div.tab_container .tab_content .gameButton a {
    height: 14vh;
  }

  div.tab_container .tab_content .gameButton a .gameImg {
    height: 9vh;
  }

  div.tab_container .tab_content .gameButton a .gameLogo {
    height: 5vh;
  }

  ul.tabs li a img {
    margin-top: 1.5vh;
  }
}
@media (max-width: 390px) and (min-width: 376px) {
  .index_tabs {
    height: 68vh;
  }

  ul.tabs li {
    height: 11.4vh;
  }

  ul.tabs li a {
    height: 11vh;
  }

  ul.tabs {
    height: 68vh;
  }

  div.tab_container .tab_content .gameButton {
    height: 14vh;
  }

  div.tab_container .tab_content .gameButton a {
    height: 14vh;
  }

  div.tab_container .tab_content .gameButton a .gameImg {
    height: 9vh;
  }

  div.tab_container .tab_content .gameButton a .gameLogo {
    height: 5vh;
  }

  ul.tabs li a img {
    margin-top: 1.5vh;
  }
}
@media (max-width: 820px) and (min-width: 769px) {
  .index_tabs {
    height: 68vh;
  }

  ul.tabs li {
    height: 11.55vh;
  }

  ul.tabs li a {
    height: 10.25vh;
  }

  ul.tabs {
    height: 68vh;
  }

  div.tab_container .tab_content .gameButton {
    height: 20vh;
  }

  div.tab_container .tab_content .gameButton a {
    height: 20vh;
  }

  div.tab_container .tab_content .gameButton a .gameImg {
    height: 12vh;
  }

  div.tab_container .tab_content .gameButton a .gameLogo {
    height: 8vh;
  }

  ul.tabs li a img {
    margin-top: 2vh;
  }
}
@media (max-width: 768px) and (min-width: 541px) {
  .m-dp-block {
    display: block !important;
  }

  .index_tabs {
    height: 62vh;
  }

  ul.tabs li {
    height: 10.6vh;
  }

  ul.tabs li a {
    height: 9vh;
  }

  ul.tabs {
    height: 62vh;
  }

  div.tab_container .tab_content .gameButton {
    height: 20vh;
  }

  div.tab_container .tab_content .gameButton a {
    height: 20vh;
  }

  div.tab_container .tab_content .gameButton a .gameImg {
    height: 12vh;
  }

  div.tab_container .tab_content .gameButton a .gameLogo {
    height: 8vh;
  }

  ul.tabs li a img {
    margin-top: 1vh;
  }
}
@media (max-width: 540px) and (min-width: 415px) {
  .index_tabs {
    height: 58vh;
  }

  ul.tabs li {
    height: 9.79vh;
  }

  ul.tabs li a {
    height: 9vh;
  }

  ul.tabs {
    height: 58vh;
  }

  div.tab_container .tab_content .gameButton {
    height: 20vh;
  }

  div.tab_container .tab_content .gameButton a {
    height: 20vh;
  }

  div.tab_container .tab_content .gameButton a .gameImg {
    height: 12vh;
  }

  div.tab_container .tab_content .gameButton a .gameLogo {
    height: 8vh;
  }

  ul.tabs li a img {
    margin-top: 0vh;
  }
}
@media (max-width: 912px) and (min-width: 769px) {
  .m-dp-block {
    display: block !important;
  }

  .index_tabs {
    height: 68vh;
  }

  ul.tabs li {
    height: 11.55vh;
  }

  ul.tabs li a {
    height: 10.25vh;
  }

  ul.tabs {
    height: 68vh;
  }

  div.tab_container .tab_content .gameButton {
    height: 20vh;
  }

  div.tab_container .tab_content .gameButton a {
    height: 20vh;
  }

  div.tab_container .tab_content .gameButton a .gameImg {
    height: 12vh;
  }

  div.tab_container .tab_content .gameButton a .gameLogo {
    height: 8vh;
  }

  ul.tabs li a img {
    margin-top: 2vh;
  }
}
/* 垂直 */
@media (max-width: 1024px) and (min-width: 913px) {
  .m-dp-block {
    display: block !important;
  }

  .t991-dp-none {
    display: none !important;
  }

  .afb-wrapper .row {
    justify-content: center;
  }

  .index_tabs {
    height: 46vh;
  }

  ul.tabs li {
    height: 11.75vh;
  }

  ul.tabs li a {
    height: 10.75vh;
  }

  ul.tabs {
    height: 46vh;
  }

  div.tab_container .tab_content .gameButton {
    height: 30vh;
  }

  div.tab_container .tab_content .gameButton a {
    height: 30vh;
  }

  div.tab_container .tab_content .gameButton a .gameImg {
    height: 20vh;
  }

  div.tab_container .tab_content .gameButton a .gameLogo {
    height: 10vh;
  }

  ul.tabs li a img {
    margin-top: 1vh;
  }

  .footer-wrapper01 {
    display: none;
  }
}
@media (max-width: 1280px) and (min-width: 1025px) {
  .m-dp-block {
    display: block !important;
  }

  .t991-dp-none {
    display: none !important;
  }

  .afb-wrapper .row {
    justify-content: center;
  }

  .index_tabs {
    height: 46vh;
  }

  ul.tabs li {
    height: 11.75vh;
  }

  ul.tabs li a {
    height: 10.75vh;
  }

  ul.tabs {
    height: 46vh;
  }

  div.tab_container .tab_content .gameButton {
    height: 30vh;
  }

  div.tab_container .tab_content .gameButton a {
    height: 30vh;
  }

  div.tab_container .tab_content .gameButton a .gameImg {
    height: 20vh;
  }

  div.tab_container .tab_content .gameButton a .gameLogo {
    height: 10vh;
  }

  ul.tabs li a img {
    margin-top: 1vh;
  }

  .footer-wrapper01 {
    display: none;
  }
}
/* 橫向 */
.nav-link span {
  color: #000;
}

img.icon-images {
  filter: brightness(0);
}

.btn-icontop-lg img {
  filter: brightness(0);
}

.btn-icontop-lg span {
  color: #000;
}

.afb-medias a {
  color: #000 !important;
}

.afb-medias span {
  color: #000;
}

.inside a {
  color: #aaa;
}

.btn-login, .button-register {
  text-transform: uppercase;
}

.nav-pills-qr .nav-link {
  background-color: var(--mainTheme);
}

.nav-pills-qr .nav-link.active {
  background-color: #141314;
}

.tab-content.qr {
  background-color: #141314;
}

.vl {
  border-left: 5px solid var(--mainTheme);
}

.read-more a.button {
  background-color: var(--mainTheme);
  color: #000;
}

.read-more a.button:hover {
  background-color: var(--subTheme);
  color: #000;
}

.inside .search-btn {
  color: #000 !important;
}

.footer-copy-right {
  background-color: #2b2b2b;
}

button.afb01 {
  color: #000 !important;
}

.modal-header {
  background-color: var(--mainTheme);
}

.user-profile .cd-tabs__list li {
  background-color: #2b2b2b;
}

.toggleRight-profile, .toggleLeft-profile {
  color: #000;
}

.toggleRight-profile:hover, .toggleLeft-profile:hover {
  color: #fff;
}

input, textarea {
  border-color: var(--mainTheme) !important;
}

input[type=file]::file-selector-button {
  color: #000;
}

.btn-white:hover {
  background-image: linear-gradient(to right, var(--mainTheme), var(--mainTheme));
  color: #000;
}

.inside-tab {
  background-color: #2b2b2b;
}

.game-part .h3 {
  background-color: #2b2b2b;
}

.wallet-top-left {
  background: #2b2b2b;
  border-bottom: solid 1px #2b2b2b;
}

.wallet-top-right {
  background-color: #aaa;
}

.btn-sharebox .h5 {
  color: #000 !important;
}

.button-afb {
  background-color: var(--mainTheme);
}

.button-afb a {
  color: #000;
}

.button-afb:hover {
  background-color: #d69401;
}

.btn-light {
  background-color: var(--mainTheme);
  color: #000;
}

.btn-light:hover {
  background-color: #d69401;
  background-image: linear-gradient(to right, #d69401, #d69401);
}

.text-wrap {
  background-color: var(--mainTheme);
  color: #000 !important;
}

.TogglePanel__PanelOne, .TogglePanel__PanelTwo {
  color: #000 !important;
}

.amount-button {
  color: #000;
}

.text-truncate {
  color: #000;
}

.modal-footer > button.btn.btn-primary.tim-btn-bg {
  background-color: var(--mainTheme);
  color: #000;
}

.modal-footer > button.btn.btn-primary.tim-btn-bg:hover {
  background-color: #d69401;
  color: #000;
}

#gotoTop {
  background-color: var(--mainTheme);
  color: #000;
}

#gotoTop:hover {
  background-color: #d69401;
}

.afb-dark-blue {
  background-color: #000;
}

.header-marquee.afb-black {
  background-color: #2b2b2b;
}

.afb-wrapper {
  background-color: #131413 !important;
}

.footer-bottom p:hover {
  color: var(--mainTheme);
}

.button.button-regular {
  color: #000 !important;
}

.afb01.promobtn {
  background-image: linear-gradient(to right, #aaa, #aaa);
  color: #000;
}

.faq-nav .nav-link.active {
  background-color: var(--mainTheme);
  color: #000;
}

.afb-main_nav .megamenu .nav-item-content {
  background-color: #2b2b2b;
}

.nav-bottom .nav-link {
  background-color: #2b2b2b;
}

.nav-bottom .nav-bottom-home .nav-center-bg-right, .nav-bottom .nav-bottom-home .nav-center-bg-left {
  background-color: #2b2b2b;
}

.nav-bottom .nav-bottom-home {
  background-color: transparent;
}

.nav-bottom .nav-bottom-home::after {
  background-image: linear-gradient(to right, var(--mainTheme), var(--mainTheme));
  filter: none;
}

.nav-bottom .nav-bottom-home .megamenu-icon {
  filter: brightness(0);
}

.nav-bottom {
  --backgroundColor: #2b2b2b;
}

.btn-lite {
  border: 2px solid var(--mainTheme);
  background-image: linear-gradient(to right, var(--mainTheme), var(--subTheme));
  color: #000;
}

.btn-lite:hover {
  border: 2px solid var(--mainTheme);
  background-image: linear-gradient(to right, var(--subTheme), var(--mainTheme));
  color: #000;
}

button.afb-m-menu, .button.afb-m-menu {
  background-image: -webkit-gradient(linear, left top, right top, from(var(--subTheme)), to(var(--mainTheme)));
  background-image: linear-gradient(to right, var(--subTheme), var(--mainTheme));
  color: #000;
}

button.afb-m-menu:hover, .button.afb-m-menu:hover {
  background-image: -webkit-gradient(linear, left top, right top, from(var(--mainTheme)), to(var(--subTheme)));
  background-image: linear-gradient(to right, var(--mainTheme), var(--subTheme));
}

.user-names a span:hover {
  color: var(--mainTheme);
}

#slideout p {
  background-color: var(--mainTheme);
  color: #000;
}

.afb-medias a {
  background-image: linear-gradient(to right, var(--mainTheme), var(--subTheme));
}

.afb-medias a:hover {
  background-image: linear-gradient(to right, var(--subTheme), var(--mainTheme));
}

.megamenu .megamenu-nav .nav-link:hover {
  background-color: var(--subTheme);
}

.btn-light:not(:disabled):not(.disabled).active, .btn-light:not(:disabled):not(.disabled):active, .show > .btn-light.dropdown-toggle, .btn-light.focus, .btn-light:focus {
  background-image: linear-gradient(to right, var(--mainTheme), var(--subTheme)) !important;
}

.js .cd-tabs__item--selected {
  box-shadow: inset 0 2px 0 var(--mainTheme);
  box-shadow: inset 0 2px 0 var(--mainTheme);
}

.btn-color {
  color: #000;
}