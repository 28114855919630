
 

// @media screen and (min-width: 960px) {
@media screen and (min-width: 992px) {
  
}

  .afb-logo {
    width: 20%;
    text-align: left;
  }
  .afb-logo img {
    width: 100%;
  }
  .header-memu-m {
    padding-top: 7px;
    width: 74%;
    text-align: right;
  }
  button.afb-m-menu, .button.afb-m-menu {
    width: 30px;
    height: 30px;
  }
  button.afb-m-menu svg {
    left: -5px;
    position: relative;
    top: 0px;
  }
  .btn-lite {
    width: 85px;
    height: 30px;
    white-space: nowrap;
    padding: 5px 3px!important;
    display: none;
  }
  .btn-login {
    min-width: 60px;
    height: 30px;
    padding: 5px 3px!important;
  }
  .button-register {
    min-width: 70px;
    height: 30px;
    padding: 5px 3px!important;
  }
  .btn-login, .button-register, .btn-lite, .afb-header-menu .login{
    max-width: none;
    border-radius: 10px;
  }
  .navbar {
    border-bottom: 3px solid var(--mainTheme);
  }
  .afb-header-menu .row {
    justify-content: right;
    align-items: center;
  }
  .afb-main_nav {
    display: none;
  }
  .notificationMarquee p {
    line-height: 13px;
  }
  ul, li {
    margin: 0;
    padding: 0;
    list-style: none;
  }
  .index_tabs {
    clear: left;
    width: 100%;
    margin: 10px 0;
    height: 58vh;
  }
  ul.tabs {
    width: 30%;
    height: 58vh;
    float: left;
    overflow-y: auto;
  }
  ul.tabs li {
    width: 100%;
    height: 9.75vh;
    overflow: hidden;
    position: relative;
  }
  ul.tabs li a {
    display: block;
    height: 9.25vh;
    padding: 5px 10px;
    color: var(--mainTheme);
    border: 2px solid var(--mainTheme);
    border-right: 0;
    background: #131413;
    text-decoration: none;
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
    text-align: center;
    position: relative;
  }

  ul.tabs li a img {
    width: 4.5vh;
    filter: invert(55%) sepia(300%) contrast(200%);
    padding-bottom: 0.5vh;
  }
  ul.tabs li a span {
    font-weight: 500;
    white-space: nowrap;
    display: block;
    width: 100%;
    text-align: center;
  }
  
  ul.tabs li.active a {
    background: var(--mainTheme);
    color: #131413;
    border-right: 2px solid var(--mainTheme);
  }
  ul.tabs li.active a img {
    filter: none!important;
  }
  ul.tabs li.active a:hover {
    
  }
  div.tab_container {
    float: right;
    width: 70%;
    height: 100%;
    border: 2px solid var(--mainTheme);
    background: var(--mainTheme);
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
  }
  div.tab_container .tab_content {
    padding: 2px;
    height: 100%;
    background-color: #131413;
    border-radius: 14px;
    overflow-y: auto;
    margin: 1px;
    border: 4px solid #131413;
  }
  div.tab_container .tab_content .gameButton {
    width: 49%;
    float: left;
    margin-right: 2%;
    margin-bottom: 5px;
  }
  div.tab_container .tab_content .gameButton:nth-child(2n) {
    margin-right: 0%;
  }
  div.tab_container .tab_content .gameButton a {
    border: 1px solid var(--mainTheme);
    display: block;
    border-radius: 12px;
    overflow: hidden;
  }
  div.tab_container .tab_content .gameButton a .gameImg {
    border-radius: 13px;
    width: 100%;
    height: 80px;
    overflow: hidden;
  }

  div.tab_container .tab_content .gameButton a .gameLogo {
    width: 60%;
    margin-left: 20%;
  }

  div.tab_container .tab_content::-webkit-scrollbar-track {
    border-left: 2px solid #131413;
    border-right: 1px solid #131413;
  }
  
  div.tab_container .tab_content::-webkit-scrollbar {
    width: 3px;
  }
  
  div.tab_container .tab_content::-webkit-scrollbar-thumb {
    
    border-left: 2px solid var(--mainTheme);
    border-right: 1px solid #131413;
    padding: 0;
  }
  div.tab_container .tab_content h2 {
    margin: 0 0 20px;
  }
  #mainContent {
    padding: 0!important;
  }
  .content-section {
    margin-top: -12px;
  }
  .widget .card-header a:after {
    color: var(--mainTheme);
  }
  .afb-header-menu {
    width: 100%;
  }
  .logout-btn {
    display: block;
    float: right;
    margin-left: 5px;
    margin-right: 5px;
    width: 20px;
    height: 20px;
  }
  .menu-btn {
    display: block;
    float: right;
    margin-left: 5px;
    width: 20px;
    height: 20px;
  }
  .info-icon {
    top: 0px;
  }
  img.choose-icon {
    margin-right: 0px;
  }
  button.dropbtn-single-box {
    padding: 5px 5px;
  }
  #mainContent {
    min-height: auto;
  }
  .main-stracture { 
    margin-top: -24px;
  }
  .nav-bottom .nav-bottom-home .megamenu-icon {
    width: calc(var(--size) * 0.38);
    height: calc(var(--size) * 0.38);
}
.dropdown-single-content a {
  width: 100%;
  text-align: left;
}
#gotoTop {
  display: none!important;
}
.content-section {
  min-height: none!important;
}
//galaxy fold
@media (max-width: 319px) and (min-width: 280px) {
  .index_tabs {
    height: 60vh;
  }
  ul.tabs li {
    height: 10.2vh;
  }
  ul.tabs li a {
    height: 9vh;
    padding: 5px 5px 5px 0px;
  }
  ul.tabs li a span {
    transform: scale(0.5);
  }

  ul.tabs {
    height: 60vh;
  }

  div.tab_container .tab_content .gameButton {
    height: 20vh;
    width: 100%;
  }
  div.tab_container .tab_content .gameButton a {
    height: 20vh;
  }
  div.tab_container .tab_content .gameButton a .gameImg {
    height: 12vh;
  }
  div.tab_container .tab_content .gameButton a .gameLogo {
    height: 8vh;
  }
}

//iphone SE, S8+
@media (max-width: 375px) and (min-width: 320px) {
  .index_tabs {
    height: 60vh;
  }
  ul.tabs li {
    height: 10.2vh;
  }
  ul.tabs li a {
    height: 9vh;
  }

  ul.tabs {
    height: 60vh;
  }

  div.tab_container .tab_content .gameButton {
    height: 18vh;
  }
  div.tab_container .tab_content .gameButton a {
    height: 18vh;
  }
  div.tab_container .tab_content .gameButton a .gameImg {
    height: 12vh;
  }
  div.tab_container .tab_content .gameButton a .gameLogo {
    height: 6vh;
  }
}

//iphone XR, pixel 5, S20ultra
@media (max-width: 414px) and (min-width: 391px) {
  .index_tabs {
    height: 70vh;
  }
  ul.tabs li {
    height: 11.8vh;
  }
  ul.tabs li a {
    height: 11vh;
  }

  ul.tabs {
    height: 70vh;
  }

  div.tab_container .tab_content .gameButton {
    height: 14vh;
  }
  div.tab_container .tab_content .gameButton a {
    height: 14vh;
  }
  div.tab_container .tab_content .gameButton a .gameImg {
    height: 9vh;
  }
  div.tab_container .tab_content .gameButton a .gameLogo {
    height: 5vh;
  }
  ul.tabs li a img {
    margin-top: 1.5vh;
  }
 
}

//iphone 12PRO
@media (max-width: 390px) and (min-width: 376px) {
  .index_tabs {
    height: 68vh;
  }
  ul.tabs li {
    height: 11.4vh;
  }
  ul.tabs li a {
    height: 11vh;
  }

  ul.tabs {
    height: 68vh;
  }

  div.tab_container .tab_content .gameButton {
    height: 14vh;
  }
  div.tab_container .tab_content .gameButton a {
    height: 14vh;
  }
  div.tab_container .tab_content .gameButton a .gameImg {
    height: 9vh;
  }
  div.tab_container .tab_content .gameButton a .gameLogo {
    height: 5vh;
  }
  ul.tabs li a img {
    margin-top: 1.5vh;
  }
 
}

//ipad air
@media (max-width: 820px) and (min-width: 769px) {
  .index_tabs {
    height: 68vh;
  }
  ul.tabs li {
    height: 11.55vh;
  }
  ul.tabs li a {
    height: 10.25vh;
  }

  ul.tabs {
    height: 68vh;
  }

  div.tab_container .tab_content .gameButton {
    height: 20vh;
  }
  div.tab_container .tab_content .gameButton a {
    height: 20vh;
  }
  div.tab_container .tab_content .gameButton a .gameImg {
    height: 12vh;
  }
  div.tab_container .tab_content .gameButton a .gameLogo {
    height: 8vh;
  }
  ul.tabs li a img {
    margin-top: 2vh;
  }
 
}

@media (min-width: 768px) {

}
//ipad mini
@media (max-width: 768px) and (min-width: 541px) {
  .m-dp-block {
    display: block !important;
}
  .index_tabs {
    height: 62vh;
  }
  ul.tabs li {
    height: 10.6vh;
  }
  ul.tabs li a {
    height: 9vh;
  }

  ul.tabs {
    height: 62vh;
  }

  div.tab_container .tab_content .gameButton {
    height: 20vh;
  }
  div.tab_container .tab_content .gameButton a {
    height: 20vh;
  }
  div.tab_container .tab_content .gameButton a .gameImg {
    height: 12vh;
  }
  div.tab_container .tab_content .gameButton a .gameLogo {
    height: 8vh;
  }
  ul.tabs li a img {
    margin-top: 1vh;
  }
 
}
//ipad mini
@media (max-width: 540px) and (min-width: 415px) {
  .index_tabs {
    height: 58vh;
  }
  ul.tabs li {
    height: 9.79vh;
  }
  ul.tabs li a {
    height: 9vh;
  }

  ul.tabs {
    height: 58vh;
  }

  div.tab_container .tab_content .gameButton {
    height: 20vh;
  }
  div.tab_container .tab_content .gameButton a {
    height: 20vh;
  }
  div.tab_container .tab_content .gameButton a .gameImg {
    height: 12vh;
  }
  div.tab_container .tab_content .gameButton a .gameLogo {
    height: 8vh;
  }
  ul.tabs li a img {
    margin-top: 0vh;
  }
 
}

//surface pro 7
@media (max-width: 912px) and (min-width: 769px) {
  .m-dp-block {
    display: block !important;
}
  .index_tabs {
    height: 68vh;
  }
  ul.tabs li {
    height: 11.55vh;
  }
  ul.tabs li a {
    height: 10.25vh;
  }

  ul.tabs {
    height: 68vh;
  }

  div.tab_container .tab_content .gameButton {
    height: 20vh;
  }
  div.tab_container .tab_content .gameButton a {
    height: 20vh;
  }
  div.tab_container .tab_content .gameButton a .gameImg {
    height: 12vh;
  }
  div.tab_container .tab_content .gameButton a .gameLogo {
    height: 8vh;
  }
  ul.tabs li a img {
    margin-top: 2vh;
  }
 
}

/* 垂直 */

//nest hub
@media (max-width: 1024px) and (min-width: 913px) {
  .m-dp-block {
    display: block !important;
}
  .t991-dp-none {
    display: none!important;
  }
  .afb-wrapper .row {
    justify-content: center;
  }
  .index_tabs {
    height: 46vh;
  }
  ul.tabs li {
    height: 11.75vh;
  }
  ul.tabs li a {
    height: 10.75vh;
  }

  ul.tabs {
    height: 46vh;
  }

  div.tab_container .tab_content .gameButton {
    height: 30vh;
  }
  div.tab_container .tab_content .gameButton a {
    height: 30vh;
  }
  div.tab_container .tab_content .gameButton a .gameImg {
    height: 20vh;
  }
  div.tab_container .tab_content .gameButton a .gameLogo {
    height: 10vh;
  }
  ul.tabs li a img {
    margin-top: 1vh;
  }
  .footer-wrapper01 {
    display: none;
  }
 
}

//nest hub max
@media (max-width: 1280px) and (min-width: 1025px) {
  .m-dp-block {
    display: block !important;
}
  .t991-dp-none {
    display: none!important;
  }
  .afb-wrapper .row {
    justify-content: center;
  }
  .index_tabs {
    height: 46vh;
  }
  ul.tabs li {
    height: 11.75vh;
  }
  ul.tabs li a {
    height: 10.75vh;
  }

  ul.tabs {
    height: 46vh;
  }

  div.tab_container .tab_content .gameButton {
    height: 30vh;
  }
  div.tab_container .tab_content .gameButton a {
    height: 30vh;
  }
  div.tab_container .tab_content .gameButton a .gameImg {
    height: 20vh;
  }
  div.tab_container .tab_content .gameButton a .gameLogo {
    height: 10vh;
  }
  ul.tabs li a img {
    margin-top: 1vh;
  }
  .footer-wrapper01 {
    display: none;
  }
 
}
/* 橫向 */