.nav-link span {
    color: #000;
}

img.icon-images {
    filter: brightness(0);
}

.btn-icontop-lg img {
    filter: brightness(0);
  }
  
  .btn-icontop-lg span {
    color: #000;
  }

  .afb-medias a {
    color: #000!important;
  }

  .afb-medias span {
    color: #000;
  }

.inside a {
    color: #aaa;
}
.btn-login, .button-register {
    text-transform: uppercase;
}

.nav-pills-qr .nav-link {
    background-color: var(--mainTheme);
}
.nav-pills-qr .nav-link.active {
    background-color: #141314;
}

.tab-content.qr {
    background-color: #141314;
}

.vl {
    border-left: 5px solid var(--mainTheme);
}

.read-more a.button {
    background-color: var(--mainTheme);
    color: #000;
}

.read-more a.button:hover {
    background-color: var(--subTheme);
    color: #000;
}

.inside .search-btn {
    color: #000!important;
}

.footer-copy-right {
    background-color: #2b2b2b;
}

button.afb01 {
    color: #000!important;
}

.modal-header {
    background-color: var(--mainTheme);
}

.user-profile .cd-tabs__list li {
    background-color: #2b2b2b;
}

.toggleRight-profile, .toggleLeft-profile {
    color: #000;
}
.toggleRight-profile:hover, .toggleLeft-profile:hover {
    color: #fff;
}

input, textarea {
    border-color: var(--mainTheme) !important;
}
input[type=file]::file-selector-button {
    color: #000;
   }
   .btn-white:hover {
    background-image: linear-gradient(to right, var(--mainTheme), var(--mainTheme));
    color: #000;
   }
.inside-tab {
    background-color: #2b2b2b;
}
.game-part .h3 {
    background-color: #2b2b2b;
}

.wallet-top-left {
    background: #2b2b2b;
    border-bottom: solid 1px #2b2b2b;
}

.wallet-top-right {
    background-color: #aaa;
}

.btn-sharebox .h5 {
    color: #000!important;
}

.button-afb {
    background-color: var(--mainTheme); 
}

.button-afb a {
    color: #000;
}

.button-afb:hover {
    background-color: darken($mainTheme, 20%);
}

.btn-light {
    background-color: var(--mainTheme);
    color: #000;
}

.btn-light:hover {
    background-color: darken($mainTheme, 20%);
    background-image: linear-gradient(to right, darken($mainTheme, 20%),darken($mainTheme, 20%) );
}

.text-wrap {
    background-color: var(--mainTheme);
    color: #000!important;
}

.TogglePanel__PanelOne, .TogglePanel__PanelTwo {
    color: #000!important;
}

.amount-button {
    color: #000;
}

.text-truncate {
    color: #000;
}

.modal-footer > button.btn.btn-primary.tim-btn-bg {
    background-color: var(--mainTheme);
    color: #000;
}

.modal-footer > button.btn.btn-primary.tim-btn-bg:hover {
    background-color: darken($mainTheme, 20%);
    color: #000;
}

#gotoTop {
    background-color: var(--mainTheme);
    color: #000;
}
#gotoTop:hover {
    background-color: darken($mainTheme, 20%);
}

.afb-dark-blue {
    background-color: #000;
}

.header-marquee.afb-black {
    background-color: #2b2b2b;
}

.afb-wrapper {
    background-color: #131413!important;
}

.footer-bottom p:hover {
    color: var(--mainTheme);
}

.button.button-regular {
    color: #000!important;
}

.afb01.promobtn {
    background-image: linear-gradient(to right, #aaa, #aaa);
    color: #000;
}

.faq-nav .nav-link.active {
    background-color: var(--mainTheme);
    color: #000;
}

.afb-main_nav .megamenu .nav-item-content {
    background-color: #2b2b2b;
}

.nav-bottom .nav-link {
    background-color: #2b2b2b;
}

.nav-bottom .nav-bottom-home .nav-center-bg-right, .nav-bottom .nav-bottom-home .nav-center-bg-left {
    background-color: #2b2b2b;
}

.nav-bottom .nav-bottom-home {
    background-color: transparent;
}

.nav-bottom .nav-bottom-home::after {
    background-image: linear-gradient(to right, var(--mainTheme), var(--mainTheme));
    filter: none;
}

.nav-bottom .nav-bottom-home .megamenu-icon {
    filter: brightness(0);
}

.nav-bottom {
    --backgroundColor: #2b2b2b;
}

.btn-lite {
    border: 2px solid var(--mainTheme);
    background-image: linear-gradient(to right, var(--mainTheme), var(--subTheme));
    color: #000;
}
.btn-lite:hover {
    border: 2px solid var(--mainTheme);
    background-image: linear-gradient(to right, var(--subTheme), var(--mainTheme));
    color: #000;
}
button.afb-m-menu, .button.afb-m-menu {
    background-image: -webkit-gradient(linear, left top, right top, from(var(--subTheme)), to(var(--mainTheme)));
    background-image: linear-gradient(to right, var(--subTheme), var(--mainTheme));
    color: #000;
}
button.afb-m-menu:hover, .button.afb-m-menu:hover {
    background-image: -webkit-gradient(linear, left top, right top, from(var(--mainTheme)), to(var(--subTheme)));
    background-image: linear-gradient(to right, var(--mainTheme), var(--subTheme));
}

.user-names a span:hover {
    color: var(--mainTheme);
}

#slideout p {
    background-color: var(--mainTheme);
    color: #000;
}

.afb-medias a {
    background-image: linear-gradient(to right, var(--mainTheme), var(--subTheme));
}
.afb-medias a:hover {
    background-image: linear-gradient(to right, var(--subTheme), var(--mainTheme));
}

.megamenu .megamenu-nav .nav-link:hover {
    background-color: var(--subTheme);
}

.btn-light:not(:disabled):not(.disabled).active, .btn-light:not(:disabled):not(.disabled):active, .show > .btn-light.dropdown-toggle, .btn-light.focus, .btn-light:focus {
    background-image: linear-gradient(to right, var(--mainTheme),var(--subTheme) ) !important;
}

.js .cd-tabs__item--selected {
    box-shadow: inset 0 2px 0 var(--mainTheme);
    box-shadow: inset 0 2px 0 var(--mainTheme);
}
.btn-color {
    color: #000;
}