:root {
  --lightTheme: #fff;
  --darkTheme: #141314;
  --mainTheme: #fec33f;
  --subTheme: #feb30c;
  // --contrastTheme: #ffd22f;

  --bg-mainTheme: var(--darkTheme);
  --text-mainTheme: var(--lightTheme);
}
$lightTheme: #fff;
$darkTheme: #141314;
$mainTheme: #fec33f;
$subTheme: #feb30c;
// $contrastTheme: #ffd22f;

$bg-maintheme: $darkTheme;
$text-maintheme: $lightTheme;

body {
  background-color: var(--bg-mainTheme);
}

input,
textarea {
  border-color: #c0c0c0 !important;
  &:focus {
    border-color: lighten($mainTheme, 20%) !important;
  }
}

button,
.button {
  &.afb01 {
    background-image: linear-gradient(to right, var(--mainTheme), var(--subTheme));
    &:hover {
      background-image: linear-gradient(to right, var(--subTheme), var(--mainTheme));
    }
    &.xs {
      background-image: linear-gradient(to right, var(--mainTheme), var(--subTheme));
      &:hover {
        background-image: linear-gradient(to right, var(--subTheme), var(--mainTheme));
      }
    }
  }
  &.afb-m-menu {
    border-color: var(--mainTheme);
    &:hover {
      background-image: linear-gradient(to right, var(--subTheme), var(--mainTheme));
    }
  }
  &-afb.one {
    background-image: linear-gradient(to right, var(--mainTheme), var(--subTheme));
    &:hover {
      background-image: linear-gradient(to right, var(--subTheme), var(--mainTheme));
    }
  }
}

.btn {
  &-icontop-lg {
    background-color: var(--mainTheme);
    &:hover {
      background-color: darken($mainTheme, 15%) !important;
    }
  }
  &-color {
    background-image: linear-gradient(to right, var(--mainTheme), var(--subTheme));
    &:hover {
      background-image: linear-gradient(to right, var(--subTheme), var(--mainTheme));
    }
  }
  &-white {
    color: var(--mainTheme);
    border-color: var(--mainTheme);
  }
  &-sharebox {
    background-image: linear-gradient(to left, var(--mainTheme), var(--subTheme));
    &:hover {
      background-image: linear-gradient(to left, var(--subTheme), var(--mainTheme));
    }
  }
}

.filter-button {
  &.active,
  &:hover {
    background-image: linear-gradient(to right, var(--subTheme), var(--mainTheme));
  }
}

.dropdown-single:hover .dropbtn-single {
  background: none;
}

// .vl {
//   border-color: var(--mainTheme);
// }

// .table-sticky {
//   thead {
//     .sticky-left {
//       background-color: darken($bg-maintheme, 25%) !important;
//     }
//     th {
//       background-color: darken($bg-maintheme, 20%) !important;
//       color: var(--text-mainTheme) !important;
//     }
//     &.thead-dark {
//       th {
//         background-color: darken($bg-maintheme, 20%) !important;
//         color: var(--text-mainTheme) !important;
//       }
//       .sticky-left {
//         background-color: darken($bg-maintheme, 25%) !important;
//       }
//     }
//   }
//   &.table-striped tbody {
//     td {
//       color: var(--text-mainTheme);
//     }
//     tr:nth-of-type(even) td.sticky-left {
//       color: var(--text-mainTheme);
//       background-color: #fdfdfe !important;
//     }

//     tr:nth-of-type(odd) {
//       color: var(--text-mainTheme);
//       background-color: #f0f0f1 !important;
//     }
//     tr:nth-of-type(odd) td.sticky-left {
//       color: var(--text-mainTheme);
//       background-color: #f0f0f1 !important;
//     }
//   }
// }

.pagination {
  > li > a.one {
    color: var(--text-mainTheme);
  }
}
.pagination > li > a {
  color: #000;
}

.paginationjs-page.J-paginationjs-page.active a {
  background-image: linear-gradient(to right, var(--mainTheme), var(--subTheme));
  color: #000!important;
  &:hover {
    background-image: linear-gradient(to right, var(--subTheme), var(--mainTheme));
  };
}

.modal2 {
  .modal-content {
    .popup {
      &-footer {
        button {
          background: var(--mainTheme);
          border-color: var(--mainTheme);
        }
      }
    }
  }
}

.choose-file::-webkit-file-upload-button {
  background: var(--mainTheme);
}

.unreadMSG,
.notifUnread {
  background-color: lighten($mainTheme, 50%);
}
.notif-hd-title {
  background-color: darken($mainTheme, 10%);
}
#dls-mobile {
  color: #fff;
}